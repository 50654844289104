import React from 'react';
import { Image } from 'rebass';

const policies = () => {
  return (
    // <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Image
      src={require('../images/policies.jpg')}
      style={{
        width: '50%',
        position: 'relative',
        left: '25%',
      }}
    />
    // </div>
  );
};

export default policies;
